import React, { useRef, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  IconButton,
  CircularProgress,
  Typography,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import emailjs from '@emailjs/browser';

interface ContactUsFormProps {
  title: string;
  open: boolean;
  onClose: () => void;
}

const ContactUsForm: React.FC<ContactUsFormProps> = ({
  title,
  open,
  onClose,
}) => {
  const form = useRef<HTMLFormElement>(null);
  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    message: '',
  });
  const [errors, setErrors] = useState({
    user_name: '',
    user_email: '',
    message: '',
  });
  const [loading, setLoading] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState<string | null>(null);

  const validateForm = () => {
    let valid = true;
    const newErrors = { user_name: '', user_email: '', message: '' };

    if (!formData.user_name.trim()) {
      newErrors.user_name = 'Name is required';
      valid = false;
    }

    if (!formData.user_email.trim()) {
      newErrors.user_email = 'Email is required';
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.user_email)) {
      newErrors.user_email = 'Email is not valid';
      valid = false;
    }

    if (!formData.message.trim()) {
      newErrors.message = 'Message is required';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const sendEmail = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    setFeedbackMessage(null);

    await emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID!,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID!,
        form.current!,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY!
      )
      .then(
        () => {
          setLoading(false);
          setFeedbackMessage('Message sent successfully!');
          form.current?.reset();
          setFormData({ user_name: '', user_email: '', message: '' }); // Reset form fields
        },
        (error: any) => {
          setLoading(false);
          setFeedbackMessage('Failed to send message. Please try again.');
          console.error('EmailJS error:', error);
        }
      );

    onClose(); // Optional: close the form dialog after submission
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {title}
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Box
          component="form"
          ref={form}
          onSubmit={sendEmail}
          noValidate
          autoComplete="off"
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <TextField
            label="Name"
            name="user_name"
            value={formData.user_name}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
            required
            error={!!errors.user_name}
            helperText={errors.user_name}
          />
          <TextField
            label="Email"
            name="user_email"
            value={formData.user_email}
            onChange={handleInputChange}
            variant="outlined"
            type="email"
            fullWidth
            required
            error={!!errors.user_email}
            helperText={errors.user_email}
          />
          <TextField
            label="Message"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            variant="outlined"
            multiline
            rows={4}
            fullWidth
            required
            error={!!errors.message}
            helperText={errors.message}
          />
        </Box>
        {feedbackMessage && (
          <Typography
            variant="body2"
            color={feedbackMessage.includes('successfully') ? 'green' : 'red'}
            sx={{ mt: 2 }}
          >
            {feedbackMessage}
          </Typography>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'center', p: 3 }}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={sendEmail}
          disabled={loading}
          startIcon={loading ? <CircularProgress size={20} /> : null}
        >
          {loading ? 'Sending...' : 'Send Message'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContactUsForm;
