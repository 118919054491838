import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Grid,
  Typography,
  Container,
  Card,
  CardContent,
  Chip,
} from '@mui/material';
import Lottie from 'react-lottie-player';
import logo from '../assets/logo.png'; // Example images

interface ServiceSectionProps {
  title: string;
  description: string;
  services: string[];
  lottieAnimation?: any; // Lottie animation JSON
  image?: string; // Optional image source if not using Lottie
  techIcons?: string[]; // Array of technology icons to show below the chips
}

const ServiceSection: React.FC<ServiceSectionProps> = ({
  title,
  description,
  services,
  lottieAnimation,
  image,
  techIcons = [logo, logo], // Example images
}) => {
  const cardRef = useRef<HTMLDivElement | null>(null);
  const iconsContainerRef = useRef<HTMLDivElement | null>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  // Check if icons overflow the container
  useEffect(() => {
    const checkOverflow = () => {
      if (iconsContainerRef.current) {
        setIsOverflowing(
          iconsContainerRef.current.scrollWidth >
            iconsContainerRef.current.clientWidth
        );
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow); // Recheck on resize

    return () => window.removeEventListener('resize', checkOverflow);
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: '#f4f7f8',
        py: 6,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Container maxWidth="lg">
        <Card
          ref={cardRef}
          sx={{
            position: 'relative',
            borderRadius: '16px',
            overflow: 'hidden',
            boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.1)',
            transition: 'transform 0.3s ease-in-out',
          }}
        >
          <CardContent sx={{ padding: { xs: 4, md: 6 } }}>
            <Grid container spacing={4} alignItems="center">
              {/* Left Side: Title, Description, and Services */}
              <Grid item xs={12} md={6}>
                <Typography
                  variant="h3"
                  component="h2"
                  sx={{
                    fontWeight: 'bold',
                    mb: 2,
                    fontSize: { xs: '1.8rem', md: '2.5rem' },
                  }}
                >
                  {title}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ mb: 4, fontSize: { xs: '1rem', md: '1.25rem' } }}
                >
                  {description}
                </Typography>

                {/* Services Chips */}
                <Typography
                  variant="subtitle1"
                  sx={{ mb: 2, fontWeight: 'bold' }}
                >
                  What we do:
                </Typography>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
                    gap: '16px',
                  }}
                >
                  {services.map((service, index) => (
                    <Chip
                      key={index}
                      label={service}
                      sx={{ backgroundColor: '#e0e0e0' }}
                    />
                  ))}
                </Box>

                {/* Technology Icons Below Chips */}
                <Box
                  ref={iconsContainerRef}
                  sx={{
                    display: 'flex',
                    overflow: 'hidden',
                    mt: 4,
                    width: '100%',
                    position: 'relative',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      whiteSpace: 'nowrap',
                      animation: isOverflowing
                        ? 'scroll 15s linear infinite'
                        : 'none', // Conditional animation
                      '@keyframes scroll': {
                        '0%': { transform: 'translateX(0)' },
                        '100%': { transform: 'translateX(-100%)' },
                      },
                    }}
                  >
                    {techIcons.map((icon, index) => (
                      <Box
                        key={index}
                        sx={{ display: 'inline-block', marginRight: '16px' }}
                      >
                        <img
                          src={icon}
                          alt={`Tech icon ${index + 1}`}
                          style={{ width: '50px', height: 'auto' }}
                        />
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Grid>

              {/* Right Side: Lottie Animation or Image */}
              <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 2 } }}>
                {lottieAnimation && (
                  <Lottie
                    loop
                    animationData={lottieAnimation}
                    play
                    style={{ width: '100%', height: 'auto' }}
                  />
                )}
                {image && (
                  <img
                    src={image}
                    alt="Service graphic"
                    style={{ width: '100%', height: 'auto' }}
                  />
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default ServiceSection;
