import React, { useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  Container,
  Avatar,
  Stack,
  Card,
  CardContent,
} from '@mui/material';
import Lottie from 'react-lottie-player';
import heroJson from '../assets/lottie/hero.json'; // Replace with your Lottie JSON file

import cosmicmachines from '../assets/client/cosmicmachines.png';
import dreampolymers from '../assets/client/dreampolymers.png';
import salescentric from '../assets/client/salescentric.png';
import sliceworx from '../assets/client/sliceworx.png';
import ContactUsForm from './ContactUsForm';

const HeroSection: React.FC = () => {
  const [getStartedFormOpen, setGetStartedFormOpen] = useState(false);
  const [talkToUsFormOpen, setTalkToUsFormOpen] = useState(false);
  return (
    <Box
      sx={{
        backgroundColor: '#f4f7f8',
        py: 6, // Reduced padding from py: 8 to py: 6 to decrease space above
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Container maxWidth="lg">
        <Card
          sx={{
            position: 'relative',
            borderRadius: '16px',
            overflow: 'hidden',
            boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.1)', // Card shadow
            mt: -2, // Optional margin-top reduction to fine-tune card position
          }}
        >
          <CardContent
            sx={{
              padding: { xs: 4, md: 6 }, // Increased padding inside the card
            }}
          >
            <Grid container spacing={4} alignItems="center">
              {/* Left Side: Text, Buttons, and Ratings */}
              <Grid item xs={12} md={6}>
                <Typography
                  variant="h3" // Reduce heading size for mobile responsiveness
                  component="h1"
                  sx={{
                    fontWeight: 'bold',
                    mb: 2,
                    fontSize: { xs: '1.8rem', md: '2.5rem' },
                  }} // Adjust font size
                >
                  From Concept to Reality: Turning Ideas into Impactful IT
                  Solutions with Agile Precision
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ mb: 4, fontSize: { xs: '1rem', md: '1.25rem' } }}
                >
                  We transform your ideas into impactful MVPs and full-scale
                  products with the precision of agile workflows—guiding every
                  step from concept to completion. Our expertise in design,
                  development, branding, and testing ensures your vision comes
                  to life smoothly, allowing you to focus on what you do best:
                  running your business.
                </Typography>

                {/* Call-to-Action Buttons */}
                <Stack
                  direction={{ xs: 'column', md: 'row' }}
                  spacing={2}
                  sx={{ mb: 4 }}
                >
                  <Button
                    variant="contained"
                    color="success"
                    size="large"
                    onClick={() => setGetStartedFormOpen(true)}
                    fullWidth={true} // Full-width on mobile
                    sx={{ maxWidth: { md: '200px' } }} // Limit width on larger screens
                  >
                    Get Started
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    onClick={() => setTalkToUsFormOpen(true)}
                    fullWidth={true} // Full-width on mobile
                    sx={{ maxWidth: { md: '200px' } }} // Limit width on larger screens
                  >
                    Talk to Us
                  </Button>
                </Stack>

                {/* Client Ratings */}
                <Box
                  display="flex"
                  alignItems="center"
                  gap={2}
                  flexDirection={{ xs: 'column', md: 'row' }}
                >
                  {/* Star Rating */}
                  <Box display="flex" alignItems="center" gap={0.5}>
                    <Typography variant="h5">★★★★★</Typography>
                  </Box>
                  <Box display="flex" alignItems="center" gap={1}>
                    {/* Client Avatars */}
                    <Avatar alt="Cosmic Machines" src={cosmicmachines} />
                    <Avatar alt="Dream, Polymers" src={dreampolymers} />
                    <Avatar alt="SliceWorx" src={sliceworx} />
                    <Avatar alt="Sales Centric" src={salescentric} />
                    <Typography
                      variant="body2"
                      sx={{ textAlign: { xs: 'center', md: 'left' } }}
                    >
                      Over 10+ Satisfied Clients
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              {/* Right Side: Lottie Animation */}
              <Grid item xs={12} md={6}>
                <Lottie
                  loop
                  animationData={heroJson} // Your Lottie animation JSON file
                  play
                  style={{ width: '100%', height: 'auto' }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
      <ContactUsForm
        title="Start a Project"
        open={getStartedFormOpen}
        onClose={() => setGetStartedFormOpen(false)}
      />
      <ContactUsForm
        title="Talk to Us"
        open={talkToUsFormOpen}
        onClose={() => setTalkToUsFormOpen(false)}
      />
    </Box>
  );
};

export default HeroSection;
