import React from 'react';
import HeroSection from './HeroSection';
import LatestWorkSection from './LatestWorkSection';
import ServiceSection from './ServiceSection';

import designJson from '../assets/lottie/design.json'; // Replace with your Lottie JSON file
import marketplaceJson from '../assets/lottie/marketplace.json'; // Replace with your Lottie JSON file
import mobileJson from '../assets/lottie/mobile.json'; // Replace with your Lottie JSON file
import qaJson from '../assets/lottie/qa.json'; // Replace with your Lottie JSON file
import seoJson from '../assets/lottie/seo.json'; // Replace with your Lottie JSON file
import webJson from '../assets/lottie/web.json'; // Replace with your Lottie JSON file

import TestimonialSection from './TestimonialSection';
import AboutUsSection from './AboutUsSection';

import appium from '../assets/qa/appium.png';
import cypress from '../assets/qa/cypress.png';
import jenkins from '../assets/qa/jenkins.png';
import junit from '../assets/qa/junit.png';
import postman from '../assets/qa/postman.png';
import selenium from '../assets/qa/selenium.png';
import testrail from '../assets/qa/testrail.png';

import canva from '../assets/design/canva.png';
import figma from '../assets/design/figma.png';
import illustrator from '../assets/design/illustrator.png';
import photoshop from '../assets/design/photoshop.png';

import ahrefs from '../assets/seo/ahrefs.png';
import amazonseller from '../assets/seo/amazonseller.png';
import semrush from '../assets/seo/semrush.png';
import wordpress from '../assets/seo/wordpress.png';
import shopify from '../assets/seo/shopify.png';

import angular from '../assets/web/angular.png';
import css from '../assets/web/css.png';
import django from '../assets/web/django.png';
import html from '../assets/web/html.png';
import javascript from '../assets/web/javascript.png';
import node from '../assets/web/node.png';
import react from '../assets/web/react.png';

import flutter from '../assets/mobile/flutter.png';
import java from '../assets/mobile/java.png';
import kotlin from '../assets/mobile/kotlin.png';
import reactnative from '../assets/mobile/reactnative.png';
import swift from '../assets/mobile/swift.png';

import buffer from '../assets/marketing/buffer.png';
import facebookads from '../assets/marketing/facebookads.png';
import googleads from '../assets/marketing/googleads.png';
import hubspot from '../assets/marketing/hubspot.png';
import instagram from '../assets/marketing/instagram.png';
import linkedin from '../assets/marketing/linkedin.png';

const Body: React.FC = () => {
  return (
    <>
      <div id="home">
        <HeroSection />
      </div>
      <div id="latest-work">
        <LatestWorkSection />
      </div>
      <div id="services-graphics-design">
        <ServiceSection
          title="Graphics Design"
          description="Whether it's branding, promotional materials, or digital creatives, our design team creates visually compelling graphics to communicate your message effectively. We also specialize in creating strong and memorable brand identities that resonate with your target audience."
          services={['Branding', 'Creative Design', 'Digital Graphics']}
          lottieAnimation={designJson}
          techIcons={[photoshop, illustrator, canva, figma]}
        />
      </div>
      <div id="services-web-development">
        <ServiceSection
          title="Web Development"
          description="We build responsive, user-friendly websites that align with your business needs. From simple landing pages to complex web applications, our experienced developers are ready to deliver."
          services={[
            'Frontend Development',
            'Backend Development',
            'Full Stack',
          ]}
          lottieAnimation={webJson}
          techIcons={[react, angular, node, django, javascript, css, html]}
        />
      </div>
      <div id="services-mobile-development">
        <ServiceSection
          title="Mobile Development"
          description="Our developers create high-performing mobile applications for Android, iOS, and Flutter, bringing your vision to life with modern and reliable technology."
          services={['Android', 'iOS', 'Flutter', 'Cross-Platform Apps']}
          lottieAnimation={mobileJson}
          techIcons={[java, kotlin, swift, reactnative, flutter]}
        />
      </div>
      <div id="services-qa">
        <ServiceSection
          title="QA Services"
          description="We excel in both manual and automated testing, covering mobile application testing, website testing, security testing, performance testing, and cross-browser testing to ensure your product meets the highest quality standards."
          services={[
            'Manual Testing',
            'Automation Testing',
            'Security Testing',
            'Performance Testing',
            'Cross-Browser Testing',
          ]}
          lottieAnimation={qaJson}
          techIcons={[
            appium,
            cypress,
            jenkins,
            junit,
            postman,
            selenium,
            testrail,
          ]}
        />
      </div>
      <div id="testimonial">
        <TestimonialSection />
      </div>
      <div id="services-marketplace">
        <ServiceSection
          title="Amazon Global Market SEO & E-commerce Seller Account Management"
          description="We help your business succeed in the competitive world of e-commerce by optimizing your Amazon seller account for global reach."
          services={['SEO', 'E-commerce', 'Amazon Seller Optimization']}
          lottieAnimation={marketplaceJson}
          techIcons={[amazonseller, semrush, ahrefs, wordpress, shopify]}
        />
      </div>
      <div id="services-digital-marketing">
        <ServiceSection
          title="Digital & Social Media Marketing"
          description="Our digital marketing team helps increase your brand visibility, connect with your target audience, and generate leads through effective social media strategies."
          services={['Social Media', 'Digital Marketing', 'Lead Generation']}
          lottieAnimation={seoJson}
          techIcons={[
            googleads,
            facebookads,
            instagram,
            linkedin,
            hubspot,
            buffer,
          ]}
        />
      </div>
      <div id="about-us">
        <AboutUsSection />
      </div>
    </>
  );
};

export default Body;
