import React, { useRef, useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Stack,
  Container,
  IconButton,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import cosmicmachines from '../assets/work/cosmicmachines.png';
import dreampolymers from '../assets/work/dreampolymers.png';
import sliceworx from '../assets/work/sliceworx.png';
import salescentric from '../assets/work/salescentric.png';

const LatestWorkSection: React.FC = () => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isPaused, setIsPaused] = useState(false); // State to track if the auto-scroll is paused
  const gap = 16; // px (gap between cards)

  // Function to handle left/right scroll with centering
  const handleScroll = (direction: 'left' | 'right') => {
    if (scrollRef.current) {
      const scrollContainer = scrollRef.current;
      const card = scrollContainer.firstChild as HTMLElement;
      const cardWidth = card.offsetWidth + gap; // Card width + gap between cards

      // Determine scroll amount based on card width
      const scrollAmount = direction === 'left' ? -cardWidth : cardWidth;

      // Smoothly scroll the container by the calculated amount
      scrollContainer.scrollBy({ left: scrollAmount, behavior: 'smooth' });

      // Calculate the new scroll position
      const currentScrollPosition = scrollContainer.scrollLeft + scrollAmount;

      // Find the closest card after scrolling and center it
      const closestCardPosition =
        Math.round(currentScrollPosition / cardWidth) * cardWidth;
      setTimeout(() => {
        scrollContainer.scrollTo({
          left: closestCardPosition,
          behavior: 'smooth',
        });
      }, 300); // Delay to allow the initial scroll to finish
    }
  };

  // Auto scroll every 3 seconds when not paused
  useEffect(() => {
    const interval = setInterval(() => {
      if (!isPaused) {
        handleScroll('right'); // Scroll to the right every 3 seconds
      }
    }, 3000);

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [isPaused]); // Re-run if isPaused state changes

  // Pause scrolling when the user focuses (e.g., hovers) on the slider
  const handleMouseEnter = () => setIsPaused(true);

  // Resume scrolling when the user leaves the slider
  const handleMouseLeave = () => setIsPaused(false);

  return (
    <Box sx={{ py: 8, backgroundColor: '#f9f9f9' }}>
      <Container maxWidth="lg">
        {/* Title and Subtitle */}
        <Box sx={{ textAlign: 'left', mb: 6 }}>
          <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
            Our Latest Work
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Explore our latest work—cutting-edge projects that showcase our
            expertise in transforming complex IT challenges into seamless,
            innovative solutions.
          </Typography>
        </Box>

        {/* Left and Right Arrows */}
        <Box
          sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}
        >
          {/* Left Arrow */}
          <IconButton
            onClick={() => handleScroll('left')}
            sx={{
              position: 'absolute',
              left: -20, // Adjust the arrow closer inside the card
              top: '50%',
              transform: 'translateY(-50%)', // Ensure it's perfectly centered vertically
              zIndex: 1,
              backgroundColor: 'white',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
              '&:hover': { backgroundColor: '#f4f4f4' },
              width: 40,
              height: 40,
            }}
          >
            <ArrowBackIosIcon sx={{ fontSize: '20px' }} />
          </IconButton>

          {/* Horizontal Scrollable Project Cards */}
          <Box
            ref={scrollRef}
            onMouseEnter={handleMouseEnter} // Pause auto-scroll on mouse enter
            onMouseLeave={handleMouseLeave} // Resume auto-scroll on mouse leave
            sx={{
              display: 'flex',
              overflowX: 'auto', // Enable horizontal scrolling
              scrollbarWidth: 'none', // Hide scrollbar in Firefox
              '&::-webkit-scrollbar': { display: 'none' }, // Hide scrollbar in Chrome, Safari and Edge
              gap: `${gap}px`, // Space between cards
              py: 2,
              px: 4, // Padding to prevent shadow cropping on left/right
              pb: 4, // Padding to prevent shadow cropping at the bottom
            }}
          >
            {[
              {
                title: 'Cosmic Machines',
                image: cosmicmachines,
                description:
                  'We developed a multilingual website that provides detailed machine information, optimized for seamless user experience across various languages. With a strong focus on UI/UX, the platform delivers an intuitive layout, allowing users to easily navigate and engage with machine specifications.',
                tags: ['UI/UX Design', 'PHP', 'WordPress', 'Multilingual'],
              },
              {
                title: 'Dream Polymers',
                image: dreampolymers,
                description:
                  'An e-commerce website built on WordPress, designed to streamline the process of purchasing 3D printing filaments. The site incorporates SEO optimization, marketplace integration, and a user-centric UI/UX, making it easy for customers to find and buy the products they need.',
                tags: [
                  'UI/UX Design',
                  'Figma',
                  'SEO',
                  'Google Ads',
                  'WordPress',
                  'Market Place',
                ],
              },
              {
                title: 'SliceWorx',
                image: sliceworx,
                description:
                  'A Shopify-based online store specializing in 3D printing filaments, where we emphasized UI/UX to create a smooth shopping experience. The website provides an engaging and efficient layout for customers to browse and purchase a wide range of filament products.',
                tags: ['UI/UX Design', 'Figma', 'Shopify'],
              },
              {
                title: 'Sales Centric',
                image: salescentric,
                description:
                  'An end-to-end inventory management application designed to streamline stock control and warehouse operations. With an emphasis on UI/UX, Sales Centric enables users to manage inventory efficiently from procurement to sales, enhancing operational productivity.',
                tags: ['UI/UX Design', 'React Native', 'Firebase'],
              },
            ].map((project, index) => (
              <Card
                key={index}
                sx={{
                  flex: '0 0 100%', // Full width for mobile
                  '@media (min-width: 600px)': {
                    flex: '0 0 50%', // Two cards for medium screens
                  },
                  '@media (min-width: 960px)': {
                    flex: '0 0 33.33%', // Three cards for large screens
                  },
                  borderRadius: '12px',
                  boxShadow: '0px 0px 0px rgba(0, 0, 0, 0.1)',
                  overflow: 'hidden',
                  flexShrink: 0, // Prevent cards from shrinking in flexbox
                }}
              >
                <Box
                  sx={{
                    height: '300px', // Square-shaped image (height = width)
                    overflow: 'hidden',
                    position: 'relative',
                    '&:hover img': {
                      transform: 'translateX(-20%)', // Scroll effect on hover
                      transition: 'transform 5s linear', // Auto scroll in 5s
                    },
                  }}
                >
                  <CardMedia
                    component="img"
                    alt={project.title}
                    image={project.image} // Replace with your actual image path
                    sx={{
                      width: '120%', // Reduced width to shrink image width
                      transition: 'transform 5s linear',
                      height: '100%', // Keep the height and width equal to make it square
                      objectFit: 'cover', // Ensure the image covers the square
                    }}
                  />
                </Box>
                <CardContent>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                    {project.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ mb: 2 }}
                  >
                    {project.description}
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    {project.tags.map((tag) => (
                      <Chip key={tag} label={tag} />
                    ))}
                  </Stack>
                </CardContent>
              </Card>
            ))}
          </Box>

          {/* Right Arrow */}
          <IconButton
            onClick={() => handleScroll('right')}
            sx={{
              position: 'absolute',
              right: -20, // Adjust this value to ensure it stays close to the content
              top: '50%',
              transform: 'translateY(-50%)', // Ensure it's perfectly centered vertically
              zIndex: 1,
              backgroundColor: 'white',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
              '&:hover': { backgroundColor: '#f4f4f4' },
              width: 40,
              height: 40,
            }}
          >
            <ArrowForwardIosIcon sx={{ fontSize: '20px' }} />
          </IconButton>
        </Box>
      </Container>
    </Box>
  );
};

export default LatestWorkSection;
