import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Container,
  Card,
  CardMedia,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLightbulb,
  faCogs,
  faCheckCircle,
  faPaintBrush,
  faRunning,
  faHandshake,
} from '@fortawesome/free-solid-svg-icons'; // Import icons

import team from '../assets/about/team.png';
import agile from '../assets/about/agile.png';

const AboutUsSection: React.FC = () => {
  return (
    <Box sx={{ backgroundColor: '#f4f7f8', py: 4 }}>
      <Container maxWidth="lg">
        {/* Heading and Introduction */}
        <Typography
          variant="h4"
          component="h2"
          sx={{
            fontWeight: 'bold',
            mb: 4,
            fontSize: { xs: '1.5rem', md: '2.5rem' },
          }}
        >
          ABOUT US
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: { xs: '1rem', md: '1.25rem' },
            mb: 4,
          }}
        >
          At BlushBit Technologies, we turn ideas into innovative, high-impact
          products that help your business grow. We’re not just tech
          experts—we're your partners in navigating the digital landscape, using
          cutting-edge tools and agile practices to bring your vision to life.
          <br />
          <br />
          We believe that every great product starts with a bright idea, and our
          mission is to make those ideas shine. We’re a passionate team of
          creators, thinkers, and builders working remotely to deliver seamless
          IT solutions that help you focus on what you do best: running your
          business.
          <br />
          <br />
          Our user-first approach means we’re committed to crafting solutions
          that are not only functional but also delightful to use. Whether
          you're looking for web and mobile development, QA services, digital
          marketing, or graphics design, we’ve got you covered. With BlushBit
          Technologies by your side, you can be confident that your project is
          in expert hands.
        </Typography>

        {/* Images Section */}
        <Grid container spacing={4} sx={{ mb: 6 }}>
          <Grid item xs={12} md={6}>
            <Card sx={{ borderRadius: '12px', overflow: 'hidden' }}>
              <CardMedia
                component="img"
                height="250"
                image={team} // Replace with actual image
                alt="Our team at work"
              />
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ borderRadius: '12px', overflow: 'hidden' }}>
              <CardMedia
                component="img"
                height="250"
                image={agile} // Replace with actual image
                alt="Our workspace"
              />
            </Card>
          </Grid>
        </Grid>

        {/* Key Values or Features */}
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <Box sx={{ textAlign: 'center' }}>
              <FontAwesomeIcon
                icon={faLightbulb}
                size="3x"
                style={{ color: '#30D6B2' }}
              />
              <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2 }}>
                Your Vision, Our Expertise
              </Typography>
              <Typography variant="body2" color="textSecondary">
                We dive deep into understanding your unique business needs and
                goals, ensuring our solutions align perfectly with what you want
                to achieve.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Box sx={{ textAlign: 'center' }}>
              <FontAwesomeIcon
                icon={faPaintBrush}
                size="3x"
                style={{ color: '#30D6B2' }}
              />
              <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2 }}>
                Creative Minds at Work
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Our team brings fresh ideas, innovative designs, and thoughtful
                experiences to make your product truly stand out.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Box sx={{ textAlign: 'center' }}>
              <FontAwesomeIcon
                icon={faRunning}
                size="3x"
                style={{ color: '#30D6B2' }}
              />
              <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2 }}>
                Agility in Action
              </Typography>
              <Typography variant="body2" color="textSecondary">
                We follow agile processes that ensure flexibility, efficiency,
                and transparency—adapting swiftly to your evolving needs.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Box sx={{ textAlign: 'center' }}>
              <FontAwesomeIcon
                icon={faCogs}
                size="3x"
                style={{ color: '#30D6B2' }}
              />
              <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2 }}>
                Technical Mastery
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Using the latest tech, we build robust, future-proof solutions
                that grow with your business.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Box sx={{ textAlign: 'center' }}>
              <FontAwesomeIcon
                icon={faHandshake}
                size="3x"
                style={{ color: '#30D6B2' }}
              />
              <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2 }}>
                Collaboration is Key
              </Typography>
              <Typography variant="body2" color="textSecondary">
                We see ourselves as an extension of your team—working closely
                with you to make the entire development journey smooth, clear,
                and successful.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Box sx={{ textAlign: 'center' }}>
              <FontAwesomeIcon
                icon={faCheckCircle}
                size="3x"
                style={{ color: '#30D6B2' }}
              />
              <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2 }}>
                Quality Comes First
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Our rigorous testing standards mean you get a product that's
                polished, reliable, and ready to wow your users.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default AboutUsSection;
