import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Link,
  Box,
  Grid,
  Container,
  Menu,
  MenuItem,
  Collapse,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListItemButton from '@mui/material/ListItemButton';
import logo from '../assets/logo.png';
import ContactUsForm from './ContactUsForm';

const Header: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [elevated, setElevated] = useState(false);
  const [activeSection, setActiveSection] = useState('home');
  const [servicesMenuAnchor, setServicesMenuAnchor] =
    useState<null | HTMLElement>(null);
  const [servicesExpanded, setServicesExpanded] = useState(false); // For mobile view
  const [activeSubSection, setActiveSubSection] = useState<string | null>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [contactFormOpen, setContactFormOpen] = useState(false);

  const handleScroll = (sectionId: string) => {
    const targetElement = document.getElementById(sectionId);

    if (targetElement) {
      const targetPosition =
        targetElement.getBoundingClientRect().top + window.scrollY;
      const startPosition = window.scrollY;
      const distance = targetPosition - startPosition;
      const duration = 800; // 800ms for the scroll duration
      let startTime: number | null = null;

      const scrollStep = (currentTime: number) => {
        if (!startTime) startTime = currentTime;
        const timeElapsed = currentTime - startTime;
        const progress = Math.min(timeElapsed / duration, 1);
        const easeInOutQuad =
          progress < 0.5
            ? 2 * progress * progress
            : -1 + (4 - 2 * progress) * progress;

        window.scrollTo(0, startPosition + distance * easeInOutQuad);

        if (progress < 1) {
          requestAnimationFrame(scrollStep);
        } else {
          // Set active section once scrolling is complete
          setActiveSection(
            sectionId.startsWith('services-') ? 'services' : sectionId
          );
          setActiveSubSection(
            sectionId.startsWith('services-') ? sectionId : null
          );
          setDrawerOpen(false);
        }
      };

      requestAnimationFrame(scrollStep);
    }
  };

  const handleToggleDrawer =
    (open: boolean) => (event: React.MouseEvent<HTMLButtonElement>) => {
      setDrawerOpen(open);
    };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Tab' || event.key === 'Shift') return;
    setDrawerOpen(false);
  };

  const handleServicesMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setServicesMenuAnchor(event.currentTarget);
  };
  const handleServicesMenuClose = () => setServicesMenuAnchor(null);
  const toggleServicesExpanded = () => setServicesExpanded(!servicesExpanded);

  useEffect(() => {
    const handleScroll = () => {
      setElevated(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const sections = [
      'home',
      'latest-work',
      'services',
      'testimonial',
      'about-us',
    ];
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const id = entry.target.id;
            if (id.startsWith('services-')) {
              setActiveSection('services');
              setActiveSubSection(id);
            } else {
              setActiveSection(id);
              setActiveSubSection(null);
            }
          }
        });
      },
      { threshold: 0.5 }
    );

    sections
      .concat([
        'services-graphics-design',
        'services-web-development',
        'services-mobile-development',
        'services-qa',
        'services-marketplace',
        'services-digital-marketing',
      ])
      .forEach((id) => {
        const section = document.getElementById(id);
        if (section) observer.observe(section);
      });

    return () =>
      sections.forEach((id) => {
        const section = document.getElementById(id);
        if (section) observer.unobserve(section);
      });
  }, []);

  const drawerContent = (
    <Box sx={{ width: 250 }} role="presentation" onKeyDown={handleKeyDown}>
      <List>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => handleScroll('home')}
            selected={activeSection === 'home'}
          >
            <ListItemText primary="Home" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => handleScroll('latest-work')}
            selected={activeSection === 'latest-work'}
          >
            <ListItemText primary="Our Latest Work" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={toggleServicesExpanded}
            selected={activeSection === 'services'}
          >
            <ListItemText primary="Services" />
            <ArrowDropDownIcon />
          </ListItemButton>
        </ListItem>
        <Collapse in={servicesExpanded} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => handleScroll('services-graphics-design')}
                selected={activeSubSection === 'services-graphics-design'}
              >
                <ArrowRightIcon />
                <ListItemText primary="Graphics Design" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => handleScroll('services-web-development')}
                selected={activeSubSection === 'services-web-development'}
              >
                <ArrowRightIcon />
                <ListItemText primary="Web Development" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => handleScroll('services-mobile-development')}
                selected={activeSubSection === 'services-mobile-development'}
              >
                <ArrowRightIcon />
                <ListItemText primary="Mobile Development" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => handleScroll('services-qa')}
                selected={activeSubSection === 'services-qa'}
              >
                <ArrowRightIcon />
                <ListItemText primary="QA Services" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => handleScroll('services-marketplace')}
                selected={activeSubSection === 'services-marketplace'}
              >
                <ArrowRightIcon />
                <ListItemText primary="Amazon Marketplace" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => handleScroll('services-digital-marketing')}
                selected={activeSubSection === 'services-digital-marketing'}
              >
                <ArrowRightIcon />
                <ListItemText primary="Digital Marketing" />
              </ListItemButton>
            </ListItem>
          </List>
        </Collapse>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => handleScroll('testimonial')}
            selected={activeSection === 'testimonial'}
          >
            <ListItemText primary="Testimonial" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => handleScroll('about-us')}
            selected={activeSection === 'about-us'}
          >
            <ListItemText primary="About Us" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <AppBar position="sticky" color="default" elevation={elevated ? 4 : 0}>
      <Container maxWidth="lg">
        <Toolbar>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={2} sx={{ textAlign: 'left' }}>
              <Link href="/" underline="none">
                <img
                  src={logo}
                  alt="BlushBit Technologies Logo"
                  style={{ height: '40px', width: 'auto' }}
                />
              </Link>
            </Grid>

            <Grid
              item
              xs={8}
              sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}
            >
              {!isMobile && (
                <>
                  <Button
                    onClick={() => handleScroll('home')}
                    sx={{
                      color:
                        activeSection === 'home' ? 'primary.main' : 'inherit',
                    }}
                  >
                    Home
                  </Button>
                  <Button
                    onClick={() => handleScroll('latest-work')}
                    sx={{
                      color:
                        activeSection === 'latest-work'
                          ? 'primary.main'
                          : 'inherit',
                    }}
                  >
                    Our Latest Work
                  </Button>
                  <Button
                    aria-controls="services-menu"
                    aria-haspopup="true"
                    onClick={handleServicesMenuOpen}
                    sx={{
                      color:
                        activeSection === 'services'
                          ? 'primary.main'
                          : 'inherit',
                    }}
                    endIcon={<ArrowDropDownIcon />}
                  >
                    Services
                  </Button>
                  <Menu
                    id="services-menu"
                    anchorEl={servicesMenuAnchor}
                    open={Boolean(servicesMenuAnchor)}
                    onClose={handleServicesMenuClose}
                  >
                    <MenuItem
                      onClick={() => handleScroll('services-graphics-design')}
                      sx={{
                        color:
                          activeSubSection === 'services-graphics-design'
                            ? 'primary.main'
                            : 'inherit',
                      }}
                    >
                      Graphics Design
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleScroll('services-web-development')}
                      sx={{
                        color:
                          activeSubSection === 'services-web-development'
                            ? 'primary.main'
                            : 'inherit',
                      }}
                    >
                      Web Development
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleScroll('services-mobile-development')
                      }
                      sx={{
                        color:
                          activeSubSection === 'services-mobile-development'
                            ? 'primary.main'
                            : 'inherit',
                      }}
                    >
                      Mobile Development
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleScroll('services-qa')}
                      sx={{
                        color:
                          activeSubSection === 'services-qa'
                            ? 'primary.main'
                            : 'inherit',
                      }}
                    >
                      QA Services
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleScroll('services-marketplace')}
                      sx={{
                        color:
                          activeSubSection === 'services-marketplace'
                            ? 'primary.main'
                            : 'inherit',
                      }}
                    >
                      Amazon Marketplace
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleScroll('services-digital-marketing')}
                      sx={{
                        color:
                          activeSubSection === 'services-digital-marketing'
                            ? 'primary.main'
                            : 'inherit',
                      }}
                    >
                      Digital Marketing
                    </MenuItem>
                  </Menu>
                  <Button
                    onClick={() => handleScroll('testimonial')}
                    sx={{
                      color:
                        activeSection === 'testimonial'
                          ? 'primary.main'
                          : 'inherit',
                    }}
                  >
                    Testimonial
                  </Button>
                  <Button
                    onClick={() => handleScroll('about-us')}
                    sx={{
                      color:
                        activeSection === 'about-us'
                          ? 'primary.main'
                          : 'inherit',
                    }}
                  >
                    About Us
                  </Button>
                </>
              )}
            </Grid>

            <Grid item xs={2} sx={{ textAlign: 'right' }}>
              {!isMobile ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setContactFormOpen(true)}
                >
                  Hire Our Team
                </Button>
              ) : (
                <IconButton
                  edge="end"
                  color="inherit"
                  aria-label="menu"
                  onClick={handleToggleDrawer(true)}
                >
                  <MenuIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Toolbar>

        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={handleToggleDrawer(false)}
        >
          {drawerContent}
        </Drawer>
        <ContactUsForm
          title="Hire Our Team"
          open={contactFormOpen}
          onClose={() => setContactFormOpen(false)}
        />
      </Container>
    </AppBar>
  );
};

export default Header;
