import React from 'react';
import { Box, Typography, Container, Avatar, Stack, Grid } from '@mui/material';

import cosmicmachines from '../assets/client/cosmicmachines.png';
import dreampolymers from '../assets/client/dreampolymers.png';

// Testimonial data structure
interface Testimonial {
  quote: string;
  name: string;
  title: string;
  avatar: string;
}

const testimonials: Testimonial[] = [
  {
    quote:
      "Thanks to BlushBit Technologies, our e-commerce site is exactly what we needed to take our business to the next level. They were incredibly knowledgeable and easy to work with, always available to answer questions and offer solutions. The site is intuitive, and our customers love the seamless shopping experience it provides. We're thrilled with the outcome!",
    name: 'Sujit Patel',
    title: 'Co-Founder of Cosmic Machines',
    avatar: cosmicmachines, // Replace with the path to the avatar image
  },
  {
    quote:
      'BlushBit Technologies exceeded our expectations! The team was highly skilled, responsive, and delivered a top-quality website on time. Their attention to detail and expertise made the entire process smooth and enjoyable.',
    name: 'Jigar Shah',
    title: 'Co-founder of Dream Polymers',
    avatar: dreampolymers, // Replace with the path to the avatar image
  },
  // Add more testimonials as needed
];

const TestimonialSection: React.FC = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#f4f7f8',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Container maxWidth="md">
        {/* Heading */}
        <Typography
          variant="h4"
          component="h2"
          sx={{
            textAlign: 'center',
            fontWeight: 'bold',
            mb: 6,
            fontSize: { xs: '1.5rem', md: '2.5rem' },
          }}
        >
          DON’T TAKE OUR WORD FOR IT! <br /> HEAR IT FROM OUR CLIENTS.
        </Typography>

        {/* Display multiple testimonials */}
        {testimonials.map((testimonial, index) => (
          <Box key={index} sx={{ mb: 6 }}>
            {/* Testimonial Quote */}
            <Typography
              variant="body1"
              sx={{
                fontSize: { xs: '1rem', md: '1.25rem' },
                textAlign: 'left', // Align left
                mb: 2,
              }}
            >
              {`"${testimonial.quote}"`}
            </Typography>

            {/* Client Info */}
            <Stack direction="row" spacing={2} alignItems="center">
              <Avatar
                alt={testimonial.name}
                src={testimonial.avatar} // Client avatar image
                sx={{ width: 56, height: 56, bgcolor: 'gray' }} // Adjust size and color if needed
              />
              <Box>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                  {testimonial.name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {testimonial.title}
                </Typography>
              </Box>
            </Stack>
          </Box>
        ))}
      </Container>
    </Box>
  );
};

export default TestimonialSection;
