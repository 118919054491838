import React, { useCallback } from 'react';
import { Container, Grid, Typography, Link, Box } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import logo from '../assets/logo.png';

const Footer: React.FC = () => {
  const scrollToSection = useCallback((sectionId: string) => {
    const targetElement = document.getElementById(sectionId);

    if (targetElement) {
      const targetPosition =
        targetElement.getBoundingClientRect().top + window.scrollY;
      const startPosition = window.scrollY;
      const distance = targetPosition - startPosition;
      const duration = 800; // 800ms for the scroll duration
      let startTime: number | null = null;

      const scrollStep = (currentTime: number) => {
        if (!startTime) startTime = currentTime;
        const timeElapsed = currentTime - startTime;
        const progress = Math.min(timeElapsed / duration, 1);
        const easeInOutQuad =
          progress < 0.5
            ? 2 * progress * progress
            : -1 + (4 - 2 * progress) * progress;

        window.scrollTo(0, startPosition + distance * easeInOutQuad);

        if (progress < 1) {
          requestAnimationFrame(scrollStep);
        }
      };

      requestAnimationFrame(scrollStep);
    }
  }, []);

  return (
    <Box sx={{ backgroundColor: '#1A2E7C', color: '#fff', py: 4 }}>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={4}
          justifyContent={{ xs: 'center', md: 'space-between' }}
          textAlign={{ xs: 'center', md: 'left' }}
        >
          {/* Company Logo with Description */}
          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                backgroundColor: '#fff',
                borderRadius: '8px',
                p: 1,
                display: 'inline-block',
                mb: 2,
              }}
            >
              <Link href="/" underline="none">
                <img
                  src={logo}
                  alt="BlushBit Technologies Logo"
                  style={{ height: '40px', width: 'auto' }}
                />
              </Link>
            </Box>
            <Typography variant="body2" sx={{ mt: 1 }}>
              Providing innovative IT solutions in QA, Web Development, Mobile
              Applications, and beyond. ❤️
            </Typography>
          </Grid>

          {/* Quick Links */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom>
              Quick Links
            </Typography>
            <Link
              onClick={() => scrollToSection('home')}
              color="inherit"
              sx={{ display: 'block', py: 0.5, cursor: 'pointer' }}
            >
              Home
            </Link>
            <Link
              onClick={() => scrollToSection('latest-work')}
              color="inherit"
              sx={{ display: 'block', py: 0.5, cursor: 'pointer' }}
            >
              Our Latest Work
            </Link>
            <Link
              onClick={() => scrollToSection('testimonial')}
              color="inherit"
              sx={{ display: 'block', py: 0.5, cursor: 'pointer' }}
            >
              Testimonials
            </Link>
            <Link
              onClick={() => scrollToSection('about')}
              color="inherit"
              sx={{ display: 'block', py: 0.5, cursor: 'pointer' }}
            >
              About Us
            </Link>
          </Grid>

          {/* Services Section */}
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{ textAlign: { xs: 'center', md: 'left' } }}
          >
            <Typography variant="h6" gutterBottom>
              Services
            </Typography>
            <Link
              onClick={() => scrollToSection('services-graphics-design')}
              color="inherit"
              sx={{ display: 'block', py: 0.5, cursor: 'pointer' }}
            >
              Graphics Design
            </Link>
            <Link
              onClick={() => scrollToSection('services-web-development')}
              color="inherit"
              sx={{ display: 'block', py: 0.5, cursor: 'pointer' }}
            >
              Web Development
            </Link>
            <Link
              onClick={() => scrollToSection('services-mobile-development')}
              color="inherit"
              sx={{ display: 'block', py: 0.5, cursor: 'pointer' }}
            >
              Mobile Development
            </Link>
            <Link
              onClick={() => scrollToSection('services-qa')}
              color="inherit"
              sx={{ display: 'block', py: 0.5, cursor: 'pointer' }}
            >
              QA Services
            </Link>
            <Link
              onClick={() => scrollToSection('services-digital-marketing')}
              color="inherit"
              sx={{ display: 'block', py: 0.5, cursor: 'pointer' }}
            >
              Digital Marketing
            </Link>
          </Grid>

          {/* Contact Information and Social Media */}
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{ textAlign: { xs: 'center', md: 'left' } }}
          >
            <Typography variant="h6" gutterBottom>
              Contact Us
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: { xs: 'center', md: 'flex-start' },
                py: 0.5,
              }}
            >
              <EmailIcon sx={{ mr: 1 }} />
              <Typography variant="body2">info@blushbit.com</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: { xs: 'center', md: 'flex-start' },
                py: 0.5,
              }}
            >
              <PhoneIcon sx={{ mr: 1 }} />
              <Typography variant="body2">+91 73832 68679</Typography>
            </Box>
            <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
              Follow Us
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: { xs: 'center', md: 'flex-start' },
                gap: 1,
              }}
            >
              {/* <Link
                href="https://facebook.com"
                color="inherit"
                target="_blank"
                aria-label="Facebook"
              >
                <FacebookIcon fontSize="large" />
              </Link>
              <Link
                href="https://twitter.com"
                color="inherit"
                target="_blank"
                aria-label="Twitter"
              >
                <TwitterIcon fontSize="large" />
              </Link> */}
              <Link
                href="https://www.linkedin.com/in/blushbit-technologies-493a22197/?originalSubdomain=in"
                color="inherit"
                target="_blank"
                aria-label="LinkedIn"
              >
                <LinkedInIcon fontSize="large" />
              </Link>
            </Box>
          </Grid>
        </Grid>

        {/* Copyright Section */}
        <Typography variant="body2" sx={{ mt: 4, textAlign: 'center' }}>
          © {new Date().getFullYear()} BlushBit Technologies. All rights
          reserved.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
