import React from 'react';
import { CssBaseline, Box } from '@mui/material';
import Header from './components/Header';
import Body from './components/Body';
import Footer from './components/Footer';
import { HelmetProvider } from 'react-helmet-async';

const App: React.FC = () => {
  return (
    <HelmetProvider>
      <CssBaseline />
      {/* Set up the flexbox layout */}
      <Box
        sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}
      >
        {/* Header */}
        <Header />

        {/* Body with flex-grow to push the footer */}
        <Box sx={{ flexGrow: 1 }}>
          <Body />
        </Box>

        {/* Footer at the bottom */}
        <Footer />
      </Box>
    </HelmetProvider>
  );
};

export default App;
